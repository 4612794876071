import {
    GetRequest, PostRequest, successParam
} from '@/api/base';

export interface GetDeviceOwnerList {
    ID: string;
    MAC: string;
    Status: string;
    SipAccount: string;
    Location: string;
    CreateTime: string;
    NetGroupNumber: string;
    StairShow: string;
    Relay: string;
    SipType: string;
    Flags: string;
    SecurityRelay: string;
    Special: number;
    ArmingFunction: string;
}
export function getDeviceOwnerList(params: object, callback: Function) {
    GetRequest('v3/web/community/device/getOwnerList', params, callback);
}

export function getDeviceList(params: object, callback: Function) {
    GetRequest('v3/web/community/device/getList', params, callback);
}

export function getListForInsDevice(params: object, callback: Function) {
    GetRequest('v3/web/community/device/getListForIns', params, callback);
}

export function deleteDevice(params: object, callback: successParam) {
    PostRequest('v3/web/community/device/delete', params, callback);
}

export function getListForPmDevice(params: object, callback: Function) {
    GetRequest('v3/web/community/device/getListForPm', params, callback);
}
