
import {
    defineComponent, ref, PropType,
    reactive
} from 'vue';
import { checkSpecialCharacters } from '@/methods/rules/device';
import { editThirdPartDeviceForPm } from '@/api/community/third-party-device';

export interface FormData {
    ID: string;
    Location: string;
    UnitName: string;
    RoomName: string;
}
const rules = {
    Location: [{
        required: true,
        message: WordList.RuleLocationEmpty,
        trigger: 'change'
    }, {
        validator: checkSpecialCharacters,
        trigger: 'change'
    }]
};
export default defineComponent({
    emits: ['close', 'success'],
    props: {
        formData: {
            type: Object as PropType<FormData>,
            required: true
        }
    },
    setup(props, { emit }) {
        const formEle = ref();
        const data = reactive({ ...props.formData });

        function submit() {
            formEle.value.validate((valid: boolean) => {
                if (valid) {
                    editThirdPartDeviceForPm({
                        ID: data.ID,
                        Location: data.Location
                    }, () => {
                        emit('close');
                        emit('success');
                    });
                }
            });
        }
        return {
            formEle,
            data,
            submit,
            rules
        };
    }
});
