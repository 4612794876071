import { PostRequest, successParam } from '@/api/base';

export function addThirdPartDevice(params: object, callback: successParam) {
    PostRequest('v3/web/community/thirdPartDevice/add', params, callback);
}

export function editThirdPartDevice(params: object, callback: successParam) {
    PostRequest('v3/web/community/thirdPartDevice/edit', params, callback);
}

export function editThirdPartDeviceForPm(params: object, callback: successParam) {
    PostRequest('v3/web/community/thirdPartDevice/editForPm', params, callback);
}